import './App.scss';
import { Switch, Route } from 'react-router-dom';
import Index from './pages/index'
import Home from './pages/home'
import Invite from './pages/invite'
import Task from './pages/task'
import Proxy from './pages/proxy';

function App() {
  
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact>
          <Index />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="/invite">
          <Invite />
        </Route>
        <Route path="/task">
          <Task />
        </Route>
        <Route path="/proxy">
          <Proxy />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
