import out from '../assets/out.svg'
import { useTranslation } from 'react-i18next'
import back from '../assets/back.svg'
import { memo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Store from 'store'

import './header.scss'

const Header = ({ isHome = false, title }) => {
    const {t} = useTranslation()
    const history = useHistory()

    const goBack = useCallback(() => {
        history.push('/home')
    }, [history ])

    const signOut = useCallback(() => {
        Store.remove('user')
        history.push('/')
    }, [history])

    return (
        <>
            {
                isHome ? (
                    <div className="isHome headers">
                        <span className="left">{t('admin')}</span>
                        <span className="right" onClick={signOut}>
                            <img src={out} alt="" className="out-img" />
                            {t('out')}
                        </span>
                    </div>
                ): <div className="headers">
                    <i className="back" onClick={goBack}>
                        <img src={back} alt="" />
                    </i>
                    <h3>{title}</h3>
                </div>
            }
        </>
    )
}

export default memo(Header);