import { InfiniteScroll, Toast } from 'antd-mobile/2x';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import http from '../lib/http'
import useSWR from 'swr';
import Header from "../components/header"
import './task.scss'

const Task = () => {
    const {t} = useTranslation()

    const [page, setPage] = useState(1)
    const [list, setList] = useState([])
    const [total, setTotal] = useState(1)
    const {data:listData, error: err, mutate}  = useSWR(`/agentbackend/task?limit=10&page=${page}`, (url) => http({ url: url, method: 'get'}), {revalidateOnFocus: false, errorRetryCount: 2})

    const loadMore = useCallback(() => {
        setPage(parseInt(page + 1));
    }, [page])

    const del = useCallback((id) => {
        Toast.show({
            icon: 'loading',
            maskClickable: false,
            duration: 0
        })
        http({ url: `/agentbackend/task/${id}`, method: 'delete'})
            .then(res => {
                if (res.status === 0) {
                    Toast.clear()
                    mutate(`/agentbackend/task?limit=10&page=${page}`)
                } else {
                    Toast.show({
                        icon: 'fail',
                        content: res.message,
                        maskClickable: false
                    })
                }
            })
            .catch(() => {
                Toast.clear()
            })
    }, [mutate, page])

    useEffect(() => {
        Toast.show({
            icon: 'loading',
            maskClickable: false,
            duration: 0
        })
        if (listData || err) {
            Toast.clear()
        }
        console.log(err);

        if (listData && listData.status === 0) {
            const { data: {data, total_page, current_page} } = listData
            setTotal(total_page);
            if (current_page=== 1) {
                setList([...data])
            } else {
                setList(v => [...v, ...data]);
            }
        }

    }, [listData, err]);

    return (
        <div className="task">
            <Header title={t('list3Title')}></Header>
            <div className="tables">
                <h3>{t('list3Detail')}</h3>
                <div className="tables-header">
                    <span>{t('No')}</span>
                    <span>{t('type')}</span>
                    <span>{t('state')}</span>
                    <span>{t('edit')}</span>
                </div>
                <div className="lists">
                    {
                        list.map(item => (
                            <div key={item.id} className="tables-body">
                                <span>{item.id}</span>
                                <span>{item.type === 1 ? t('sell') :t('buy')}</span>
                                <span 
                                    className={item.deleted_at ? 'del-font': ''}>
                                    {item.deleted_at ? t('del') :t('online')}
                                </span>
                                <span className="btns">
                                    {!item.deleted_at ? 
                                        <i className="del-btn" onClick={del.bind(this, item.id)}>
                                            {t('del_btn')}
                                        </i> : ''}
                                </span>
                            </div>
                        ))
                    }
                    <InfiniteScroll loadMore={loadMore} hasMore={total-page > 0} />
                </div>
            </div>
        </div>
    )
}

export default Task;