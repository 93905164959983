import { useTranslation } from 'react-i18next';
import {useCallback, useEffect, useState} from 'react'
import { Button, Toast } from 'antd-mobile/2x'
import http from '../lib/http'
import Store from 'store'
import './index.scss';

function Index() {
  const {t} = useTranslation()

  const [email, setEamil] = useState('')
  const [pwd,setPwd] = useState('')
  const [loading, setLoading] = useState(false)
  const [timeId, setTimeId] = useState()

  const goApp = useCallback(
    () => {
      window.location.href = 'glk://'

      const appDownload = setTimeout(() => {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //判断是否是 android终端
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端
        if (isAndroid) {
          window.location.href = 'http://43.129.212.177/uploads/res/__UNI__F80FE83_0830212311.apk'
        } else if (isIOS) {
          alert('open ios')
          window.location.href = 'http://43.129.212.177/uploads/res/__UNI__F80FE83_0830212311.apk'
        }
      }, 3000);
      setTimeId(appDownload)
    },
    [],
  )

  const changeEamil = useCallback((e) => {
    setEamil(e.target.value)
  }, [])

  const changePwd = useCallback((e) => {
    setPwd(e.target.value)
  }, [])

  useEffect(() => {

      window.addEventListener('visibilitychange', () => {
        clearTimeout(timeId)
      }, false)
      return () => {
        clearTimeout(timeId)
      }
  }, [timeId])

  const goHome = useCallback(() => {
    if (!email) {
      Toast.show({
        icon: 'fail',
        content: t('plz_account'),
        maskClickable: false
      })
      return
    } 
    if (!pwd) {
      Toast.show({
        icon: 'fail',
        content: t('plz_pwd'),
        maskClickable: false
      })
      return
    }
    if (!loading) {
      setLoading(true)
      http({ url: '/agentbackend/user/login', method: "POST", data: { email, password: pwd} })
        .then(res => {
          setLoading(false)
          if (res.status !== 0) {
            Toast.show({
              icon: 'fail',
              content: res.message,
              maskClickable: false
            })
          } else {
            const { data } = res;
            Store.set('user', {...data})
            setTimeout(() => {
              window.location.href = '/home'
            }, 300)
          }
        })
        .catch(err => {
          console.log(err, 'err');
          setLoading(false)
        })
    }
  }, [email, pwd, t, loading])

  return (
    <div className="Index">
      <h1>{t('title')}</h1>
      <div className="group-input">
        <span>{t('account')}</span>
        <input type="text" onChange={changeEamil} placeholder={t('plz_account')}/>
      </div>
      <div className="group-input">
        <span>{t('pwd')}</span>
        <input type="password" onChange={changePwd} placeholder={t('plz_pwd')}/>
      </div>
      <p className="warn" onClick={goApp}>
        {t('forget')}
        <span>{t('find')}</span>
      </p>
      <div className="button-group">
        <Button color="primary" loading={loading} disabled={loading} onClick={goHome}>{t('login')}</Button>
      </div>
    </div>
  );
}

export default Index;