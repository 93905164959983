
import { InfiniteScroll, Toast } from 'antd-mobile/2x'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../components/header'
import http from '../lib/http'
import useSWR from 'swr'
import moment from 'moment'
import './invite.scss'

const Invite = () => {
    const { t } = useTranslation()

    const [page, setPage] = useState(1)
    const [list, setList] = useState([])
    const [total, setTotal] = useState(1)
    const {data:listData, error: err}  = useSWR(`/agentbackend/user/agent-friend?limit=10&page=${page}`, (url) => http({ url: url, method: 'get'}), {revalidateOnFocus: false, errorRetryCount: 2})
    const {data:totalData } = useSWR('/agentbackend/user/dashboard-data', (url) => http({ url: url, method: 'get'}), {revalidateOnFocus: false, errorRetryCount: 2})

    const totalInvite = useMemo(() => {
        return (totalData && totalData.status === 0 && totalData.data.total) || 0
    }, [totalData])

    const loadMore = useCallback(() => {
        setPage(parseInt(page + 1));
    }, [page])

    useEffect(() => {
        Toast.show({
            icon: 'loading',
            maskClickable: false,
            duration: 0
        })
        if (listData || err) {
            Toast.clear()
        }

        if (listData && listData.status === 0) {
            const { data: {data, total_page} } = listData
            setTotal(total_page);
            setList(v => [...v, ...data]);
        }

    }, [listData, err])


    return (
        <div className="Invite">
            <Header title={t('list1Title')}></Header>
            <div className="invite-num">
                <p>{t('total')}</p>
                <span>{totalInvite}</span>
            </div>
            <div className="tables">
                <div className="tables-header">
                    <span>ID</span>
                    <span>{t('account')}</span>
                    <span>{t('num')}</span>
                    <span>{t('invite_id')}</span>
                    <span>{t('type')}</span>
                    <span>{t('in_time')}</span>
                </div>

                <div className="lists">
                    {
                        list.map(item => (
                            <div key={item.id} className="tables-body">
                                <span>{item.id}</span>
                                <span>{item.name}</span>
                                <span>{item.usdt}</span>
                                <span>{item.parent_id}</span>
                                <span>{item.type === 2 ? t('boss') : t('agent') }</span>
                                <span>{moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}</span>
                            </div>
                        ))
                    }
                    <InfiniteScroll loadMore={loadMore} hasMore={total-page > 0} />
                </div>
            </div>
        </div>
    )
}

export default Invite