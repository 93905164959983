import { InfiniteScroll, Toast } from 'antd-mobile/2x'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../components/header'
import http from '../lib/http'
import useSWR from 'swr'
import './proxy.scss'

const Proxy = () => {
    const { t } = useTranslation()

    const [page, setPage] = useState(1)
    const [list, setList] = useState([])
    const [total, setTotal] = useState(1)
    const {data:listData, error:err}  = useSWR(`/agentbackend/user/agent?limit=10&page=${page}`, (url) => http({ url: url, method: 'get'}), {revalidateOnFocus: false, errorRetryCount: 2})
    const {data:totalData } = useSWR('/agentbackend/user/dashboard-data', (url) => http({ url: url, method: 'get'}), {revalidateOnFocus: false, errorRetryCount: 2})

    const totalTitle = useMemo(() => {
        return (totalData && totalData.status === 0 && {
            total: totalData.data.total,
            bill: totalData.data.bill
        }) || {
            total: 0,
            bill: 0
        }
    }, [totalData])

    const loadMore = useCallback(() => {
        setPage(parseInt(page + 1));
    }, [page])

    useEffect(() => {
        Toast.show({
            icon: 'loading',
            maskClickable: false,
            duration: 0
        })
        if (listData || err) {
            Toast.clear()
        }

        if (listData && listData.status === 0) {
            const { data: {data, total_page} } = listData
            setTotal(total_page);
            setList(v => [...v, ...data]);
        }

    }, [listData, err])

    return (
        <div className="Proxy">
            <Header title={t('list2Title')}></Header>
            <div className="proxy-group">
                <div className="proxy-items items1">
                    <p>{t('proxy_total')}</p>
                    <span>{totalTitle.total}</span>
                </div>
                <div className="proxy-items items2">
                    <p>{t('proxy_total_money')}</p>
                    <span>{totalTitle.bill}</span>
                </div>
            </div>
            <div className="tables">
                <div className="tables-header">
                    <span>{t('account')}</span>
                    <span>{t('level')}</span>
                    <span>{t('num')}</span>
                    <span>{t('directNum')}</span>
                    <span>{t('directMoney')}</span>
                </div>

                <div className="lists">
                    {
                        list.map(item => (
                            <div key={item.id} className="tables-body">
                                <span>{item.email}</span>
                                <span>{item.level}</span>
                                <span>{item.usdt}</span>
                                <span>{item.direct_count}</span>
                                <span>{item.direct_usdt}</span>
                            </div>
                        ))
                    }
                    <InfiniteScroll loadMore={loadMore} hasMore={total-page > 0} />
                </div>
            </div>
        </div>
    )
}

export default Proxy 