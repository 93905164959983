const zhCN = {
    title: "后台管理系统",
    account: "账号",
    plz_account: "请输您的账号",
    pwd: "密码",
    plz_pwd: "请输您的登录密码",
    login: "登录",
    forget: "忘记密码？",
    find: "请至APP找回",
    admin: "代理后台",
    out: "退出",
    list1Title: "邀请好友",
    list1Detail: "邀请好友信息列表",
    list2Title: "代理信息",
    list2Detail: "代理的信息列表",
    list3Title: "任务广告",
    list3Detail: "我的任务广告",
    list4Title: "站内信",
    list4Detail: "站内信息列表",
    total: "邀请总人数",
    num: "余额",
    invite_id: "推荐者ID",
    type: "类型",
    in_time: "加入时间",
    No: "编号",
    state:  "状态",
    edit: "编辑",
    buy: "回购",
    sell: "出售",
    online: "在线",
    del: "已删除",
    del_btn: "删除",
    level: "等级",
    directNum: "直推人数",
    directMoney: "直推账单额",
    proxy_total: "总人数",
    proxy_total_money: "总账单额(u)",
    agent: "商户",
    boss: "金主"
}

export default zhCN;